import React from "react";
import "../css/testimonial.css";
import {
  Navigation,
  Autoplay,
  Pagination,
  Scrollbar,
  A11y,
} from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { useNavigate } from "react-router-dom";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

const Testimonial = () => {
  const navigate = useNavigate();

  return (
    <section className="testimonial-section">
      <Swiper
        modules={[Navigation, Autoplay, Pagination, Scrollbar, A11y]}
        autoplay={{ delay: 5000, disableOnInteraction: false }}
        spaceBetween={30}
        slidesPerView={1}
        style={{ maxWidth: "100%", minWidth: "300px", padding: "0px 0px" }}
        pagination={{ clickable: true }}
        // navigation={true}
        breakpoints={{
          320: {
            navigation: false,
          },
          480: {
            navigation: false,
          },
          628: {
            navigation: false,
          },
        }}
      >
        <SwiperSlide>
          <div className="slider-card-testimonial testimonial-content">
            <p>
              <span>
                <i class="fa-solid fa-quote-left"></i>
              </span>
              Amazing experience. Budget friendly. Food was also very tasty. Rooms are spacious and well maintained.highly recommended ❤️
              <span>
                <i class="fa-solid fa-quote-right"></i>
              </span>
            </p>
            <h2>- Vijay Dhondge</h2>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="slider-card-testimonial testimonial-content">
            <p>
              <span>
                <i class="fa-solid fa-quote-left"></i>
              </span>
              Love the view from my room. Its a homely and budget friendly stay. The best thing about this hotel is walking distance from mall road.
              <span>
                <i class="fa-solid fa-quote-right"></i>
              </span>
            </p>
            <h2>- Jatin Thakre</h2>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="slider-card-testimonial testimonial-content">
            <p>
              <span>
                <i class="fa-solid fa-quote-left"></i>
              </span>
              
I stayed at this hotel during Diwali 2023, and it exceeded my expectations. The location is perfect, close to tourist spots and Mall Road, making it an ideal base for exploring Mussoorie. Unlike other hotels, the food here was outstanding and reasonably priced. Must-try dishes include Egg Paratha, Lemon Tea, Half-Fried Egg, and Butter Toast.
              <span>
                <i class="fa-solid fa-quote-right"></i>
              </span>
            </p>
            <h2>- Ayushman Gupta</h2>
          </div>
        </SwiperSlide>
      </Swiper>
    </section>
  );
};

export default Testimonial;
