import React from "react";
import "../css/introduction-section.css";
import IntroductionImg from "../assets/hero.jpg";

const IntroductionSection = () => {
  return (
    <section className="introduction-section">
      <div className="introduction-section-right swipe">
        <h1 className="after">
          Welcome To <br />
          <h1>Hotel Kahkashan Mussoorie</h1>
        </h1>
        <p>
        Ideally located at a distance of 1.4 kms from Mall road, 3 kms from Camel’s back road and just 0.5 kms from Library bus stand and all other amenities that a person can expect for so Hotel Kahkashan is a perfect place to reside in. The property offers mesmerizing view of the mountains. The hotel is committed to make guest stay comfortable by offering them with 24 hours front desk and room service. The staff of the hotel is co-operative and keen to help guest. The in-house restaurant offers excellent mouth watering Indian, Continental and Chinese food and cosy dining room. The morning walk in the hills embedded with natural beauty is an exhilarating and peaceful experience. To provide travel guidance to its guest the hotel operates its own travel desk. To summarize Hotel Kahkashan is a decent hotel to fulfils the accommodation need of the guest visiting ‘Queen of hills’.

        The attractions like Kempty fall is 15 kms, Laal Tibba (6 kms), St. Emilans Church (3 kms), Gun hill (2 kms) while the Dehradun Jolly Grant airport and Dehradun railway stations is located at a distance of 60 kms and 35 kms respectively from Hotel Kahkashan. Paid pick up and drop facility to railway station and airport is available on request, which demonstrates your convenience is our top priority.
        </p>
      </div>
      <div className="introduction-section-left swipe">
        <img
          src={IntroductionImg}
          alt="Hotel Kahkashan Mussoorie Image"
          loading="eager"
          width="100%"
          height="auto"
        />
      </div>
    </section>
  );
};

export default IntroductionSection;
